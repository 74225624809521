import { Environments } from './environments.enum';

export const environment = {
  environment: Environments.PROD,
  production: true,
  PORT: 4200,
  APP_URL: 'https://qr.waytr.app',
  API_URL: 'https://api.waytr.app',
  WS_URL: 'wss://api.waytr.app',
  API_KEY: 'oMK?*wIbjlTLg9rrV@MZ8jZ"H}n<aaeR',
  LOCAL_STORAGE_KEY: 'f3z|c<_sjIJk@"aKP2::AZ!S.F0|fc}+',
  GEOLOCATION_API_URL: 'https://ipwho.is',
};
